import React, { Fragment, Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Wistia extends Component {

  state = {
    loaded: false
  }

  componentDidMount() {
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <script async src={`https://fast.wistia.com/embed/medias/${this.props.wistiaId}.jsonp`}/>
          <script async src={'https://fast.wistia.com/assets/external/E-v1.js'} charset="ISO-8859-1" />
        </Helmet>
        <div className="Wistia">
          <div
            className="wistia_responsive_padding"
            style={{ padding: '56.25% 0 0 0', position: 'relative' }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}
            >
              <span
                className={`wistia_embed wistia_async_${this.props.wistiaId} popoverAnimateThumbnail=true videoFoam=true popover=true`}
                style={{ display: 'inline-block', height: '100%', position: 'relative', width: '100%' }}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}