import React, { Fragment, useState } from 'react';
import GatsbyImage from 'gatsby-image';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { css } from '@emotion/react';

export default function Gallery({ data, imagesPerRow }) {

  const [currentImage, setCurrentImage] = useState(0);
  const [showLightbox, setShowLightbox] = useState(false);

  const openLightbox = (e, index) => {
    e.preventDefault();
    setCurrentImage(index);
    setShowLightbox(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setShowLightbox(false);
  };

  const renderGallery = () => {

    if (!data) return null;

    return (
      <div className="row">
        {
          data.map((obj, i) => {
            return (
              <div
                className={`col-sm-${(12 / imagesPerRow)}`}
                key={i}
                css={css`
            margin-bottom: 2rem;
            `}>
                <a
                  href={obj.image.localFile.sharp.fluid.src}
                  onClick={(e) => openLightbox(e, i)}
                >
                  <GatsbyImage
                    fluid={obj.image.localFile.sharp.fluid}
                    css={css`
                  background-color: #F8F9FA;
                  background-size: contain;
                  height: 100%;
                  width: 100%;
                  margin: 1rem;
                  `}
                  />
                </a>
              </div>
            );
          })
        }
      </div>
    );
  };

  return (
    <Fragment>
      {renderGallery()}
      {
        <ModalGateway>
          {showLightbox &&
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={data.map(el => ({
                  src: el.image.localFile.sharp.fluid.src
                }))}
              />
            </Modal>
          }
        </ModalGateway>
      }
    </Fragment>
  );

}